import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from '../components/Loading';

const Home = lazy(() => import('../views/Home'));
const Reporte = lazy(() => import('../views/Reporte'));

export default function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/admin/panel/reporte" element={<Reporte />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}